import * as React from 'react';
import classNames from 'classnames';
import {
  DisplayMode,
  LanguageSelectorLayoutProps,
  LanguageSelectorWithPreviewProps,
} from '../LanguageSelector.types';
import { testIds } from '../constants';
import { LanguageSelectorNativeDropdown } from './LanguageSelectorNativeDropDown';
import style from './style/LanguageSelector.scss';
import { LanguageSelectorHorizontal } from './LanguageSelectorHorizontal';
import { LanguageSelectorDropdown } from './LanguageSelectorDropDown';

const selectors: {
  [key in DisplayMode]: React.FC<LanguageSelectorLayoutProps>;
} = {
  dropdown: LanguageSelectorDropdown,
  nativeDropdown: LanguageSelectorNativeDropdown,
  horizontal: LanguageSelectorHorizontal,
} as const;

const LanguageSelector: React.FC<LanguageSelectorWithPreviewProps> = ({
  id,
  items,
  displayMode,
  onChange,
  alignItems,
  extraOptions,
  translate,
  onMouseEnter,
  disabled,
  isOpen = false,
  setIsOpen,
}) => {
  if (!items) {
    return <div id={id}></div>;
  }
  const Selector = selectors[displayMode];

  return (
    <div
      id={id}
      className={classNames(style.container, style[alignItems], {
        [style.disabled]: disabled,
      })}
      data-testid={testIds.languageSelectorContainer}
      onMouseEnter={onMouseEnter}
    >
      <Selector
        id={id}
        translate={translate}
        items={items}
        onChange={onChange}
        alignItems={alignItems}
        extraOptions={extraOptions}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        disabled={disabled}
      />
    </div>
  );
};

export default LanguageSelector;
