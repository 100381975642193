import { TranslationGetter } from '@wix/editor-elements-types';
import { Translations } from '../../constants';

export function getAriaLabel(
  translate: TranslationGetter | undefined,
  label: string,
) {
  const ariaLabel = (
    translate
      ? translate(
          Translations.ariaLabelNamespace,
          Translations.ariaLabelKey,
          Translations.ariaLabelDefault,
        )
      : Translations.ariaLabelDefault
  ).replace('{{language}}', label);

  return ariaLabel;
}
