import React from 'react';
import { LanguageSelectorLayoutProps } from '../LanguageSelector.types';
import {
  LanguageSelectorDropDownHandle,
  useOrganizeItems,
} from './LanguageSelectorDropDownCommon';
import styleNative from './style/DropdownNative.scss';
import { getAriaLabel } from './common/AriaLabelGetter';

export const LanguageSelectorNativeDropdown = ({
  items,
  onChange,
  translate,
  extraOptions = {},
}: LanguageSelectorLayoutProps) => {
  const options = useOrganizeItems(items);
  const ariaLabel = getAriaLabel(translate, options[0].label);
  const { showArrow = true, showHandleText = true } = extraOptions;

  return (
    <LanguageSelectorDropDownHandle
      item={options[0]}
      className={styleNative.native}
      showArrow={showArrow}
      showText={showHandleText}
      aria-label={ariaLabel}
    >
      <select onChange={e => onChange(e.target.value)}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </LanguageSelectorDropDownHandle>
  );
};
